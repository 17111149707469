import InlineLoadingSpinnerDark from '/svg/inline_spinner_dark.svg';

// Keep a count of how many loading requests are currently active. E.g., if two
// requests are started, loading indicator should continue spinning after the
// 1st request completes and stop spinning when both are completed.
let currentlyLoading = 0;
let afterLoadingCallbacks = [];

export function startLoadingIndicator() {
    if (currentlyLoading++ === 0) {
        $("#preloader").addClass("preload_add");
        $("#preloader").show();
    }
}

export function stopLoadingIndicator() {
    if (--currentlyLoading === 0) {
        $("#preloader").hide();
        $("#preloader").removeClass("preload_add");

        while (afterLoadingCallbacks.length > 0 && currentlyLoading === 0) {
            afterLoadingCallbacks.shift()();
        }
    }
}

/**
 * @callback SpinnerWaitCallback
 * @param {boolean} immediate Whether callback was invoked immediately (true) or with a delay (false)
 */

/**
 * Guarantees that when callback is invoked, spinner is not shown
 * @param {SpinnerWaitCallback} callback
 */
export function waitForLoadingIndicatorStop(callback) {
    if (currentlyLoading === 0) {
        callback(true);
    } else {
        afterLoadingCallbacks.push(() => callback(false));
    }
}

/**
 * @param {HTMLInputElement} inputElement
 */
export function startInputLoadingIndicator(inputElement) {
    inputElement.readOnly = true;

    const inputWrapper = document.createElement('div');
    inputWrapper.classList.add('js-input-spinner');
    inputWrapper.style.cssText = `position: relative`;
    inputElement.insertAdjacentElement('beforebegin', inputWrapper);
    inputWrapper.appendChild(inputElement);

    const spinnerWrapper = document.createElement('div');
    spinnerWrapper.style.cssText = `
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 6px; /* This is random number, not related to any existing styles */
        pointer-events: none;
    `;
    inputWrapper.appendChild(spinnerWrapper);

    const spinner = document.createElement('img');
    spinner.src = InlineLoadingSpinnerDark;
    spinner.classList.add('inline-loading-spinner');
    spinnerWrapper.appendChild(spinner);
}

/**
 * @param {HTMLInputElement} inputElement
 */
export function stopInputLoadingIndicator(inputElement) {
    const inputWrapper = inputElement.closest('.js-input-spinner');
    inputWrapper.insertAdjacentElement('beforebegin', inputElement);
    inputWrapper.remove();

    inputElement.readOnly = false;
}

export function addBoxLoader(boxEl) {
    const overlay = document.createElement('div');
    overlay.classList.add('overlay');
    const overlayIcon = document.createElement('img');
    overlayIcon.src = InlineLoadingSpinnerDark;
    overlayIcon.classList.add('inline-loading-spinner');
    overlay.appendChild(overlayIcon);

    boxEl.appendChild(overlay);

    return overlay;
}
