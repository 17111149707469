import $ from 'jquery';
import {startLoadingIndicator, stopLoadingIndicator} from './loading';
import {
    PRODUCT_TYPE_PERCENT_FROM_GROSS,
    PRODUCT_TYPE_PERCENT_FROM_NET,
    PRODUCT_TYPE_PERCENT_LODGING_GROSS,
    PRODUCT_TYPE_PERCENT_LODGING_NET,
    PRODUCT_TYPE_PERCENT_PACKPRICE_GROSS,
    PRODUCT_TYPE_PERCENT_PACKPRICE_NET,
} from './constants';

export function decimalFormat(value, decimals) {
    return numberFormat(Number(value), decimals, 1, window.config.basicSettings.default_numeral_format);
}

export function currencyFormat(value) {
    return window.config.basicSettings.setting_site_currency + ' ' + decimalFormat(value, 2);
}

export function dateFormat(value) {
    return moment(value).format(window.config.basicSettings.setting_default_dateformat.toUpperCase());
}

export function dateShortFormat(value) {
    let format = window.config.basicSettings.setting_default_dateformat === 'yyyy/mm/dd' ? 'mm/dd' : 'dd.mm';
    return moment(value).format(format.toUpperCase());
}

export function datetimeFormat(value) {
    return moment(value).format(window.config.basicSettings.setting_default_dateformat.toUpperCase() + ' HH:mm');
}

export function applyTimezone(value) {
    return moment(value).tz(window.config.basicSettings.setting_country_time_zone);
}

export function durationFormat(value) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - hours * 3600) / 60);
    const seconds = value - hours * 3600 - minutes * 60;

    let result = (hours !== 0 ? `${hours}h` : '') + (minutes !== 0 ? ` ${minutes}m` : '') + (seconds !== 0 ? ` ${seconds}s` : '');

    // Adds spaces after hours and minutes if followed by a number
    return result.trim();
}

/**
 * Y-m-d format, ignoring timezone
 */
export function dateYMD(date) {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 0-index
    const day = (date.getDate()).toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function translate(category, message, params = {}) {
    return translateRaw(category, message, params).join('');
}

/**
 * Similar to `translate` but gives the raw tokens instead of a plain string.
 *
 * Examples:
 *
 * input: '{username} created damage "{title}"'
 * output: ['{username}', ' created damage "', '{title}', '"']
 *
 * input: 'Create password'
 * output ['Create password']
 */
export function translateRaw(category, message, params = {}) {
    if (window.hasOwnProperty('translations') && window.translations.hasOwnProperty(category) && window.translations[category].hasOwnProperty(message)) {
        message = window.translations[category][message];
    }

    return tokenizeTranslation(message)
        .map((token) => Array.isArray(token) ? parseToken(token, params) : token);
}

/**
 * Tokenizes a pattern by separating normal text from replaceable patterns.
 *
 * Examples:
 *
 * input: '{username} created damage "{title}"'
 * output: [['username'], 'created damage "', ['title'], '"']
 *
 * input: 'Create password'
 * output ['Create password']
 *
 * input: '{username} {newStatus,select,IN_PROGRESS{started resolving} COMPLETED{resolved}} damage "{title}"'
 * output: [['username'], ['newStatus', 'select', 'IN_PROGRESS{started resolving} COMPLETED{resolved}'], 'damage "', ['title'], '"']
 */
function tokenizeTranslation(message) {
    let pos = message.indexOf('{');
    let start = pos;
    if (pos === -1) {
        // No tokens are present
        return [message];
    }

    let depth = 1;
    const tokens = [
        message.substr(0, pos),
    ];
    while (true) {
        let open = message.indexOf('{', pos + 1);
        let close = message.indexOf('}', pos + 1);
        if (open === -1 && close === -1) {
            break;
        }
        if (open === -1) {
            open = message.length;
        }
        if (close > open) {
            ++depth;
            pos = open;
        } else {
            --depth;
            pos = close;
        }
        if (depth === 0) {
            tokens.push(
                message.substr(start + 1, pos - start - 1).split(',', 3),
            );
            start = pos + 1;
            tokens.push(
                message.substr(start, open - start),
            );
            start = open;
        }
        if (depth !== 0 && (open === -1 || close === -1)) {
            break;
        }
    }

    return tokens;
}

function parseToken(token, params) {
    const [param, type, args] = token;
    if (!params.hasOwnProperty(param)) {
        return '{' + token.join(',') + '}';
    }
    const arg = params[param];

    if (type === 'select') {
        const select = tokenizeTranslation(args);
        for (let i = 0; i < select.length - 1; i += 2) {
            if (Array.isArray(select[i]) || !Array.isArray(select[i + 1])) {
                // Wrong format. Return as is
                return '{' + token.join(',') + '}';
            }
            const selector = select[i].trim();
            if (selector === arg || selector === 'other') {
                return select[i + 1];
            }
        }
    } else {
        return arg;
    }

    return '{' + token.join(',') + '}';
}

/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * (x * 255).clamp(0, 255)
 *
 * @param {Number} value
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 */
export function clamp(value, min, max) {
    return Math.min(Math.max(value || 0, min), max);
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// https://davidwalsh.name/javascript-debounce-function
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function handleXhrError(error) {
    if (error.status === 302) {
        return;
    }

    console.error(error);
    if (error.responseJSON) {
        alert(`${error.status}: ${error.responseJSON.name}\n${error.responseJSON.message}`);
    } else {
        alert(`${error.status}: ${error.responseText}`);
    }
}

export async function handleFetchError(response) {
    let message = translate('frontend', 'ERROR_INPROCESS');
    if (response.headers.get('Content-Type').indexOf('application/json') !== -1) {
        message = (await response.json()).message;
    }
    alert(`${response.status}: ${message}`);
}

/**
 * @typedef GetCountryRegionsFulfilled
 * @property {string} optionsHtml
 */
/**
 * @param countryId
 * @returns {Promise<GetCountryRegionsFulfilled>}
 */
export function getCountryRegions(countryId) {
    return new Promise((resolve, reject) => {
        $.ajax({
            type: 'GET',
            url: '/country-region/index',
            data: {country_id: countryId},
            dataType: 'json',
            beforeSend: () => startLoadingIndicator(),
            complete: () => stopLoadingIndicator(),
            success: (response) => {
                let optionsHtml = `<option value="">${translate('frontend', 'SELECT_REGION')}</option>`;

                for (const [id, name] of Object.entries(response)) {
                    const option = document.createElement('option');
                    option.value = id;
                    option.textContent = name + '';

                    optionsHtml += option.outerHTML;
                }

                resolve(optionsHtml);
            },
            error: (error) => {
                handleXhrError(error);

                reject(error);
            },
        });
    });
}

/**
 * @param {Object} errorEntries
 * @param {function} formGroupResolver
 */
export function updateErrors(errorEntries, formGroupResolver) {
    let allErrorsHandled = true;
    for (let [id, errors] of Object.entries(errorEntries)) {
        const formGroup = formGroupResolver(id);
        if (formGroup) {
            formGroup.classList.add('has-error');
            let errorElement = formGroup.querySelector('.help-block');
            if (!errorElement) {
                errorElement = document.createElement('p');
                errorElement.classList.add('help-block');
                errorElement.style.whiteSpace = 'pre-line';
                formGroup.insertAdjacentElement('beforeend', errorElement);
            }

            errorElement.innerText = errors.join('\n');
        } else {
            // Error in a field thats not part of the form
            allErrorsHandled = false;
        }
    }

    return allErrorsHandled;
}

export function monthsList(locale) {
    const months = [];
    for (let monthIdx = 0; monthIdx < 12; ++monthIdx) {
        months.push(
            // use middle of month to avoid timezone issues
            (new Date(2022, monthIdx, 15)).toLocaleDateString(locale, {month: 'long'}),
        );
    }

    return months;
}

export function parseGraphQLError(error, fieldLookup) {
    if (!error.graphQLErrors || !error.graphQLErrors.length) {
        // not a graphql error response
        throw error;
    }

    const unhandledError = error.graphQLErrors.find((error) => {
        // Validation errors are for invalid data - amount too low or such.
        // Configuration is for either a disabled functionality.
        // Both are safe to show to hotel staff.

        const errorCategory = error.extensions?.category;
        return errorCategory !== 'validation' && errorCategory !== 'configuration';
    });

    if (unhandledError) {
        // Some internal error mayhaps?
        throw new Error(unhandledError.message);
    }

    const fieldErrors = {};
    const unknownErrors = [];
    for (const validationError of error.graphQLErrors) {
        let field = null;
        if (validationError.extensions.argumentPath) {
            field = fieldLookup(validationError.extensions.argumentPath);
        }

        if (field) {
            fieldErrors[field] ??= [];
            fieldErrors[field].push(validationError.message);
        } else {
            unknownErrors.push(validationError.message);
        }
    }

    return [fieldErrors, unknownErrors];
}

export function isPercentUnit(unit) {
    return unit === PRODUCT_TYPE_PERCENT_FROM_NET || unit === PRODUCT_TYPE_PERCENT_FROM_GROSS || unit === PRODUCT_TYPE_PERCENT_LODGING_NET || unit === PRODUCT_TYPE_PERCENT_LODGING_GROSS || unit === PRODUCT_TYPE_PERCENT_PACKPRICE_NET || unit === PRODUCT_TYPE_PERCENT_PACKPRICE_GROSS;
}

export function enforceDiscountInvariant() {
    const discountElement = document.getElementById('discount');
    const inputElement = document.getElementById('pack-price-disp');

    inputElement.disabled = Number(discountElement.value) === 100;
}

let computedLocalDayNames = {};
/**
 * lengthType = 'narrow' | 'short' | 'long'
 */
export function getLocalDayNames(lengthType, locale) {
    if (computedLocalDayNames[`${lengthType}_${locale}`] !== undefined) {
        return computedLocalDayNames[`${lengthType}_${locale}`]
    }

    let d = new Date(2000,0,2); // Sunday
    let days = [];

    for (let i=0; i<7; i++) {
        days.push(d.toLocaleString(locale,{weekday: lengthType}));
        d.setDate(d.getDate() + 1);
    }

    computedLocalDayNames[`${lengthType}_${locale}`] = days;

    return days;
}

let computedLocalMonthNames = {};
/**
 * lengthType = 'narrow' | 'short' | 'long'
 */
export function getLocalMonthNames(lengthType, locale) {
    if (computedLocalMonthNames[`${lengthType}_${locale}`] !== undefined) {
        return computedLocalMonthNames[`${lengthType}_${locale}`]
    }

    let d = new Date(2000,0); // January
    let months = [];

    for (let i=0; i<12; i++) {
        months.push(d.toLocaleString(locale,{month: lengthType}));
        d.setMonth(i + 1);
    }

    computedLocalMonthNames[`${lengthType}_${locale}`] = months;

    return months;
}

export function rgbToHex(rgb) {
    return `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(value => parseInt(value, 10).toString(16).padStart(2, '0')).join('')}`
}

/**
 * Similar to toFixed(...) but without rounding
 *
 * @param {number} number
 * @param {number} precision
 * @return {string}
 */
export function truncateNumber(number, precision) {
    const pattern = new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`);
    return number.toString().match(pattern)[0];
}

/**
 * Similar to setInterval, with the added behaviour that handler will be called  N+1th time only Nth time has resolved,
 * i.e., waits on the previous promise to resolve before calling the handler again.
 *
 * @param {Function} handler
 * @param {number} timeout
 * @return {Function} A function that, when called, will stop further invocations
 */
export function setWaitingInterval(handler, timeout) {
    let timeoutId = null;
    const timeoutHandler = async () => {
        await handler();
        // Interval might have been cleared while promise was resolving.
        if (timeoutId !== null) {
            timeoutId = setTimeout(timeoutHandler, timeout);
        }
    };
    timeoutId = setTimeout(timeoutHandler, timeout);

    return () => {
        // Cleanup might get called multiple times.
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }
    };
}
