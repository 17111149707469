import {setWaitingInterval} from '../utils';

function checkCsrfToken() {
    if (document.hidden) {
        return Promise.resolve();
    }

    return $.post('/site/check-token').then(function (response) {
        if (response === false) {
            intervalHandle();
            document.getElementById('csrfWarning').classList.remove('hidden');
        }
    });
}

const intervalHandle = setWaitingInterval(checkCsrfToken, 5 * 1000);
