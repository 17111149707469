// Reservation::STATUS_*
export const RESERVATION_STATUS_ACTIVE    = 1;
export const RESERVATION_STATUS_CANCELLED = 0;

// RoomSetup::CLEANING_STATUS_*
export const ROOMSETUP_CLEANING_STATUS_DIRTY = 'dirty';
export const ROOMSETUP_CLEANING_STATUS_CLEAN = 'clean';
export const ROOMSETUP_CLEANING_STATUS_CLEANING = 'cleaning';

// Property::DATEV_*
export const PROPERTY_DATEV_DISABLED = 0;
export const PROPERTY_DATEV_MANUAL_ONLY = 1;
export const PROPERTY_DATEV_WITH_API = 2;

// Property::TOURIST_TAX_SYSTEM_*
export const PROPERTY_TOURIST_TAX_SYSTEM_NONE = 'none';
export const PROPERTY_TOURIST_TAX_SYSTEM_FERATEL = 'feratel';
export const PROPERTY_TOURIST_TAX_SYSTEM_TOURX = 'tourx';

// PropertyPaymentMethods::HANDLE_AS_*
export const PAYMENT_METHOD_HANDLE_AS_UNPAID = 1;
export const PAYMENT_METHOD_HANDLE_AS_PAID_NON_CASH = 2;
export const PAYMENT_METHOD_HANDLE_AS_PAID_CASH = 3;
export const PAYMENT_METHOD_HANDLE_AS_PAID_NON_CASH_POS = 4;

// FiskalyTss::STATE_*
export const TSS_STATE_CREATED = 'CREATED';
export const TSS_STATE_UNINITIALIZED = 'UNINITIALIZED';
export const TSS_STATE_INITIALIZED = 'INITIALIZED';
export const TSS_STATE_DISABLED = 'DISABLED';

// FiskalySettings::FISKALY_VERSION_*
export const FISKALY_VERSION_1 = 'v1';
export const FISKALY_VERSION_2 = 'v2';

// HistoryEmail::TYPE_*
export const HISTORY_EMAIL_TYPE_CLIENT = 1;
export const HISTORY_EMAIL_TYPE_RESERVATION = 2;
export const HISTORY_EMAIL_TYPE_ENQUIRY = 3;
export const HISTORY_EMAIL_TYPE_INVOICE = 4;
export const HISTORY_EMAIL_TYPE_DUNNING_INVOICE = 5;
export const HISTORY_EMAIL_TYPE_AUTOMAILER = 6;

// TouristTaxJobListener::STATUS_*
export const TOURIST_TAX_JOB_LISTENER_STATUS_PENDING = 'pending';
export const TOURIST_TAX_JOB_LISTENER_STATUS_ERROR = 'error';
export const TOURIST_TAX_JOB_LISTENER_STATUS_DONE = 'done';

// ExportArtifact::STATUS_*
export const EXPORT_ARTIFACT_STATUS_PENDING = 'pending';
export const EXPORT_ARTIFACT_STATUS_CANCELLED = 'cancelled';
export const EXPORT_ARTIFACT_STATUS_DONE = 'done';

// ExportArtifact::TYPE_
export const EXPORT_ARTIFACT_TYPE_DSFINVK       = 'dsfinvk';
export const EXPORT_ARTIFACT_TYPE_GOBD          = 'gobd';
export const EXPORT_ARTIFACT_TYPE_CLIENT_EXPORT = 'client_export';
export const EXPORT_ARTIFACT_TYPE_FISKALY_V1    = 'fiskaly_v1';
export const EXPORT_ARTIFACT_TYPE_FISKALY_V2    = 'fiskaly_v2';
export const EXPORT_ARTIFACT_TYPE_DATEV         = 'datev';
export const EXPORT_ARTIFACT_TYPE_DATEV_API     = 'datev_api';

// ProductAndService::TYPE_*
export const PRODUCT_TYPE_FLAT = 1;
export const PRODUCT_TYPE_PER_PERSON = 2;
export const PRODUCT_TYPE_PER_NIGHT = 3;
export const PRODUCT_TYPE_PER_DAY = 4;
export const PRODUCT_TYPE_PER_ITEM = 5;
export const PRODUCT_TYPE_PER_PERSON_NIGHT = 9;
export const PRODUCT_TYPE_PER_PERSON_DAY = 10;
export const PRODUCT_TYPE_PERCENT_FROM_NET = 14;
export const PRODUCT_TYPE_PERCENT_FROM_GROSS = 15;
export const PRODUCT_TYPE_PERCENT_LODGING_NET     = 16;
export const PRODUCT_TYPE_PERCENT_LODGING_GROSS   = 17;
export const PRODUCT_TYPE_PERCENT_PACKPRICE_NET   = 18;
export const PRODUCT_TYPE_PERCENT_PACKPRICE_GROSS = 19;

// EmailTemplate::STATUS_*
export const EMAIL_TEMPLATE_STATUS_ACTIVE = 1;
export const EMAIL_TEMPLATE_STATUS_INACTIVE = 0;

// AutomailerSettings::MODULE_*
export const AUTOMAILER_SETTINGS_MODULE_ONLINE_CHECKIN = 'online_check_in';
export const AUTOMAILER_SETTINGS_MODULE_SELF_CHECKOUT = 'self_checkout';

// RoomAccessKey::TYPE_*
export const ROOM_ACCESS_KEY_TYPE_SALTO       = 'salto';
export const ROOM_ACCESS_KEY_TYPE_PIN         = 'pin';
export const ROOM_ACCESS_KEY_TYPE_KEY_STORAGE = 'keystorage';
export const ROOM_ACCESS_KEY_TYPE_VOSTIO      = 'vostio';

// SignAT::FON_STATUS_
export const FON_STATUS_AUTHENTICATED     = 'AUTHENTICATED';
export const FON_STATUS_UNAUTHENTICATED   = 'UNAUTHENTICATED';
export const FON_STATUS_ERROR_UNSPECIFIED = 'ERROR_UNSPECIFIED';

// VostioKeyIssuingRequest::STATUS_*
export const VOSTIO_KEY_ISSUING_REQUEST_STATUS_CREATE_PENDING = 'CREATE_PENDING';
export const VOSTIO_KEY_ISSUING_REQUEST_STATUS_CREATE_SUCCESS = 'CREATE_SUCCESS';
export const VOSTIO_KEY_ISSUING_REQUEST_STATUS_CREATE_FAILURE = 'CREATE_FAILURE';
export const VOSTIO_KEY_ISSUING_REQUEST_STATUS_WRITE_PENDING = 'WRITE_PENDING';
export const VOSTIO_KEY_ISSUING_REQUEST_STATUS_WRITE_SUCCESS = 'WRITE_SUCCESS';
export const VOSTIO_KEY_ISSUING_REQUEST_STATUS_WRITE_FAILURE = 'WRITE_FAILURE';

// VostioReadCardRequest::STATUS_*
export const VOSTIO_READ_CARD_REQUEST_STATUS_CREATE_PENDING = 'CREATE_PENDING';
export const VOSTIO_READ_CARD_REQUEST_STATUS_CREATE_SUCCESS = 'CREATE_SUCCESS';
export const VOSTIO_READ_CARD_REQUEST_STATUS_CREATE_FAILURE = 'CREATE_FAILURE';
export const VOSTIO_READ_CARD_REQUEST_STATUS_READ_PENDING = 'READ_PENDING';
export const VOSTIO_READ_CARD_REQUEST_STATUS_READ_ERROR = 'READ_ERROR';
export const VOSTIO_READ_CARD_REQUEST_STATUS_READ_KEY_FOUND = 'READ_KEY_FOUND';
export const VOSTIO_READ_CARD_REQUEST_STATUS_READ_NOT_A_GUEST_KEY = 'READ_NOT_A_GUEST_KEY';
export const VOSTIO_READ_CARD_REQUEST_STATUS_READ_EMPTY_CARD = 'READ_EMPTY_CARD';
